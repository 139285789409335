.content {
    text-align: left;
}
.title {
    font-size: 40px;
    margin: 40px 0;
}
.img {
    margin: 20px 0;
    width: 100%;
    background-repeat: no-repeat;
}
.gongsi {
    font-size: 28px;
    margin: 20px 0;
}
.address {
    font-size: 18px;
    margin: 20px 0;
}