.title {
    font-size: 30px;
}

.card {
    display: flex;
    justify-content: space-between;
}
.card-inner {
    margin: 0 10px;
}