.carousel{
    margin: 10px 0;
    background: url('../../../public/banner.png') no-repeat;
    width: 100%;
    height:500px;
    background-size: 100% ;
}
.c-button {
    text-align: center;
}
.ytb-src {
    height:600px;
    width:100%
}
.content-p {
    font-size:20px;
}
.main-product {
    margin: 30px 0;
    font-size: 32px;
    text-align: left; 
}
.card {
    display: flex;
    justify-content: space-between;
}
.card-inner {
    margin: 0 10px;
}