
.header {
    display: flex;
    align-items: center;
  }
  
  .demo-log {
    background: url('../../../public/nav_logo_full.png') no-repeat;
    width: 100px;
    height: 40%;
    background-size:  auto 100%;
  }
  .header-menu {
    flex: 1;
    min-width: 0;
  }
  
  .content {
    background: #ffffff;
    padding: 0 48px;
    min-height: 280px;
    border-radius: revert;
  }
  
  .footer {
    textAlign: center;
  }