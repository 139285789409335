.title {
    margin: 20px 0;
    font-size: 30px;
}
.text {
    font-size: 18px;
    margin: 45px 0;
}
.c-img {
    width: 100%;
    background-repeat: no-repeat;
}